<template>
    <DataTable :headers="headers"
           :data="items"
           :expandable="expandable"
           :editable="editable"
           :pagination="pagination"
           title="Actions"
           type="action" />
</template>


<script>
import { mapActions } from 'vuex'
import moment from 'moment-timezone'
import { rest } from '../_helpers'
import DataTable from '@/components/Table.vue'

export default {
    props: {
        propId: Number,
        data: {
            default() {
                return {}
            },
            type: Object,
        },
    },

    data() {
        return {
            pagination: {
                sortBy: ['projectName'],
                rowsPerPage: 25,
            },
            headers: [
                {
                    text: 'Type',
                    align: 'left',
                    sortable: true,
                    value: 'type',
                    repr: (row, value) => value.toUpperCase(),
                    type: 'string',
                },
                {
                    text: 'Start',
                    align: 'left',
                    value: 'start',
                    type: 'datetime',
                },
                {
                    text: 'End',
                    align: 'center',
                    value: 'end',
                    type: 'datetime',
                },
                {
                    text: 'Interval',
                    align: 'left',
                    sortable: false,
                    value: 'interval',
                    type: 'number',
                    width: '3em',
                },
                {
                    text: 'Interval Unit',
                    align: 'left',
                    sortable: true,
                    value: 'intervalUnit',
                    repr: (row, value) => value.toUpperCase(),
                    type: 'string',
                    width: '10em',
                },
                {
                    text: 'Repetitions',
                    align: 'left',
                    sortable: false,
                    value: 'count',
                    type: 'number',
                    width: '3em',
                },
                {
                    text: 'User',
                    align: 'left',
                    value: 'userId',
                    type: 'string',
                },
                {
                    text: 'Next',
                    align: 'left',
                    value: 'next',
                    type: 'date',
                },
                {
                    text: 'Enabled',
                    align: 'center',
                    sortable: false,
                    value: 'enabled',
                    type: 'bool',
                    width: '3em',
                    editable: true,
                },
                {
                    text: 'Created At',
                    align: 'left',
                    value: 'updatedAt',
                    type: 'datetime',
                },
                {
                    text: 'Synced',
                    align: 'left',
                    value: 'synced',
                    repr: (row, value) => {
                        if (row.date && row.synced) {
                            if (moment(row.date).isAfter(moment(row.synced))) {
                                return `<span style="color: red">${value}</span>`
                            }
                        }

                        return value
                    },
                    type: 'datetime',
                },
            ],
            items: [],
            expandable: false,
            editable: true,
        }
    },

    components: {
        DataTable,
    },

    methods: {
        ...mapActions({
            showSpinner: 'page/showSpinner',
            setNew: 'input/setNew',
            resetNew: 'input/resetNew',
        }),

        getRows() {
            const payload = {
                where: { systemId: this.propId },
                // attributes: [],
                includes: [],
            }

            rest.getRows('action', payload)
                .then(items => {
                    this.items = items
                })
                .finally(() => {
                    this.showSpinner(false)
                })
        },
    },

    mounted() {
        this.showSpinner(true)
    },

    created() {
        this.setNew({
            id: this.propId,
            type: 'action',
            title: 'Action',
            modified: (idx, item) => {
                this.items.push(item)

                this.resetNew()
            },
        })

        this.getRows()
    },
}
</script>
