<template>
    <div style="height:100%">
        <v-card flat
            class="d-flex flex-row mt-4">
            <v-card flat
                style="min-width:200px;max-width:200px;"
                class="mr-6">
                <v-select
                    v-model="logVersion"
                    :items="logVersions"
                    label="Log Version"
                    outlined
                    @change="getData(logVersion)"
                    ></v-select>
            </v-card>

            <v-card flat>
                <v-btn
                    x-large
                    class="ma-1"
                    outlined
                    color="indigo"
                    @click="downloadLog()"
                    >
                    Download
                    </v-btn>
            </v-card>
        </v-card>

        <div style="overflow: auto; height: calc( 100% - 110px );">
            <div style="white-space: pre-line">
                <pre>{{ logData }}</pre>
            </div>
            <div class="spinner"
                 v-if="page.spinner">
            </div>
        </div>
    </div>
</template>


<script>
import { mapState, mapActions } from 'vuex'
import { rest } from '../_helpers'

const moment = require('moment-timezone')

export default {
    props: {
        systemId: Number,
    },

    data() {
        return {
            logData: '',
            logVersion: 0,
            logVersions: [
                {
                    text: 'Current',
                    value: 0,
                },
                {
                    text: '1',
                    value: 1,
                },
                {
                    text: '2',
                    value: 2,
                },
                {
                    text: '3',
                    value: 3,
                },
                {
                    text: '4',
                    value: 4,
                },
            ],
        }
    },

    computed: {
        ...mapState({
            page: 'page',
        }),
    },

    methods: {
        ...mapActions({
            showSpinner: 'page/showSpinner',
        }),

        date(date) {
            return moment(date).format('YYYY-MM-DD HH:mm')
        },

        getData(number) {
            this.logData = ''
            this.showSpinner(true)

            return rest.getLogs(this.systemId, number)
                .then(response => {
                    if (response) {
                        this.logData = response
                    }
                    return true
                })
                .catch(err => {
                    this.logData = 'No Log Data found.'
                })
                .finally(() => {
                    this.showSpinner(false)
                })
        },

        downloadLog(mime) {
            const label = `${this.systemId}_${moment().format('YYYY-MM-DD_HH-mm-ss')}.txt`

            const blob = new Blob([this.logData], { type: mime ? mime : 'text/plain' })
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = label
            link.click()
            URL.revokeObjectURL(link.href)
        },

        capFirst(string) {
            return string.charAt(0).toUpperCase() + string.slice(1)
        },
    },

    mounted() {
        // this.showSpinner(false)
    },

    created() {
        this.getData(this.logVersion)
    },
}
</script>


<style>
</style>
